import * as React from 'react';
import FadedScreen from 'layout/FadedScreen';
import Header from 'components/Header';
import Hero from 'components/Hero';
import About from 'sections/About';
import Products from 'sections/Products';
import Gallery from 'sections/Gallery';
import Contact from 'sections/Contact';
import Footer from 'sections/Footer';
import {SEO} from 'components/SEO';

export default function IndexPage() {
  return (
    <FadedScreen>
      <Header />
      <div id="home">
        <Hero />
        <About />
      </div>
      <Products />
      <Gallery />
      <Contact />
      <Footer />
    </FadedScreen>
  );
}

export const Head = () => <SEO />;
