import * as React from 'react';

export default function FacebookLogo() {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8125 8.4375C9.9668 8.4375 8.4375 9.9668 8.4375 11.8125V42.1875C8.4375 44.0332 9.9668 45.5625 11.8125 45.5625H42.1875C44.0332 45.5625 45.5625 44.0332 45.5625 42.1875V11.8125C45.5625 9.9668 44.0332 8.4375 42.1875 8.4375H11.8125ZM11.8125 11.8125H42.1875V42.1875H33.4336V30.7969H37.8105L38.4434 25.7344H33.4336V22.4648C33.4336 20.9883 33.7961 19.9863 35.9121 19.9863H38.6543V15.3984C38.1863 15.3391 36.5647 15.2402 34.6992 15.2402C30.8101 15.2402 28.1602 17.5803 28.1602 21.9375V25.7344H23.7305V30.7969H28.1602V42.1875H11.8125V11.8125Z"
        fill="black"
      />
    </svg>
  );
}
