import * as React from 'react';

export default function Logo({color = '#44A3DB', size = 100, style}: Readonly<{color?: string; size?: number}>) {
  return (
    <svg width={size} height={size} viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M47.4457 35.3809C47.7122 37.6377 48.7253 37.8069 49.1631 37.8407C50.6289 37.9528 50.9398 36.8424 51.2105 35.6876C51.9296 33.6973 54.0066 5.29616 53.2833 1.53768C52.8899 -0.509703 46.551 -0.537199 45.7219 1.61171C44.7511 4.12864 47.1115 32.5615 47.4457 35.383V35.3809Z"
          fill={color}
        />
        <path
          d="M38.4332 40.1292C40.0872 41.6858 40.9734 41.1655 41.3288 40.9096C42.5238 40.053 42.0479 39.0018 41.5128 37.9443C40.7831 35.9582 24.1185 12.8638 21.1489 10.4505C19.5309 9.13706 14.6578 13.1874 15.4044 15.3659C16.28 17.9188 36.3647 38.1812 38.4311 40.1292H38.4332Z"
          fill={color}
        />
        <path
          d="M34.5815 49.5581C36.8488 49.6872 37.1936 48.7185 37.3015 48.2954C37.6653 46.872 36.6268 46.3707 35.5354 45.9054C33.6995 44.8521 6.0894 37.8745 2.26324 37.9337C0.179898 37.9655 -0.949548 44.2028 1.0217 45.3915C3.33347 46.7853 31.7431 49.3974 34.5773 49.5581H34.5815Z"
          fill={color}
        />
        <path
          d="M37.6928 59.2579C39.5139 57.9 39.1543 56.9355 38.964 56.54C38.3273 55.2139 37.2106 55.4994 36.0748 55.8442C33.9914 56.2164 8.35681 68.6192 5.4634 71.1234C3.88767 72.4876 7.03278 77.991 9.30647 77.6336C11.9715 77.2148 35.4149 60.9542 37.6907 59.2558L37.6928 59.2579Z"
          fill={color}
        />
        <path
          d="M46.3097 64.6893C46.8321 62.4791 45.9353 61.9715 45.5377 61.7896C44.1988 61.1826 43.5263 62.1216 42.8769 63.1136C41.5212 64.738 29.8545 90.7174 29.2495 94.4949C28.9196 96.5528 34.865 98.7483 36.3794 97.0118C38.1519 94.9771 45.6582 67.4537 46.3097 64.6893Z"
          fill={color}
        />
        <path
          d="M56.4021 63.3104C55.3805 61.2821 54.3695 61.4682 53.9465 61.5866C52.5315 61.9843 52.6182 63.1327 52.7599 64.3108C52.7662 66.428 60.5264 93.8266 62.4913 97.1113C63.5616 98.8986 69.5282 96.7581 69.5705 94.4569C69.6212 91.7581 57.6774 65.8485 56.4021 63.3125V63.3104Z"
          fill={color}
        />
        <path
          d="M63.2471 55.766C61.1595 54.8692 60.506 55.6623 60.2585 56.024C59.4294 57.238 60.2352 58.0629 61.1003 58.873C62.4645 60.491 86.0221 76.4915 89.6389 77.7436C91.608 78.4247 94.8018 72.9509 93.3551 71.1594C91.6609 69.0592 65.8571 56.8891 63.2471 55.766Z"
          fill={color}
        />
        <path
          d="M63.6421 45.5861C61.4657 46.2396 61.4742 47.2675 61.5186 47.7032C61.6624 49.1669 62.8109 49.2811 63.9953 49.3467C66.0808 49.7083 94.4121 46.8234 97.9866 45.4592C99.9325 44.7168 98.8622 38.4689 96.6012 38.0268C93.9532 37.5086 66.3621 44.7696 63.6421 45.5882V45.5861Z"
          fill={color}
        />
        <path
          d="M57.4007 37.5361C56.1549 39.4354 56.8212 40.218 57.1342 40.5247C58.1854 41.5526 59.1372 40.9012 60.0868 40.1905C61.9164 39.1266 81.7642 18.7057 83.6254 15.3617C84.6386 13.5407 79.8014 9.44377 77.7857 10.5584C75.4232 11.8634 58.9574 35.1609 57.3986 37.5361H57.4007Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
