import * as React from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {css} from '@emotion/react';
import {MOBILE_HEADER_HEIGHT} from 'components/Header';
import {graphql, useStaticQuery} from 'gatsby';
import type {HeroType} from './Desktop';

export default function Mobile() {
  const {
    markdownRemark: {
      frontmatter: {hero},
    },
  } = useStaticQuery<HeroType>(graphql`
    query HeroMobileQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        id
        frontmatter {
          hero {
            image {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  `);
  const img = getImage(hero.image);

  return (
    <div css={styles.root}>
      {img != null && (
        <GatsbyImage
          alt="Infinet on a basketball ring"
          css={{height: `calc(80vh - ${MOBILE_HEADER_HEIGHT}px)`, width: '100%', left: -50}}
          loading="eager"
          objectFit="contain"
          image={img}
        />
      )}
    </div>
  );
}

const styles = {
  root: css({
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  }),
};
