import * as React from 'react';
import {type ImageDataLike, GatsbyImage, getImage} from 'gatsby-plugin-image';
import {css} from '@emotion/react';
import {DESKTOP_HEADER_HEIGHT} from 'components/Header';
import {motion, useScroll, useTransform} from 'framer-motion';
import {graphql, useStaticQuery} from 'gatsby';

export type HeroType = Readonly<{
  markdownRemark: {
    frontmatter: {
      hero: {
        image: ImageDataLike;
        textImage: ImageDataLike;
      };
    };
  };
}>;

export default function Desktop() {
  const {
    markdownRemark: {
      frontmatter: {hero},
    },
  } = useStaticQuery<HeroType>(graphql`
    query HeroDesktopQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        id
        frontmatter {
          hero {
            image {
              childImageSharp {
                gatsbyImageData(width: 1440)
              }
            }
            textImage {
              childImageSharp {
                gatsbyImageData(width: 1440)
              }
            }
          }
        }
      }
    }
  `);
  const img = getImage(hero.image);
  const textImage = getImage(hero.textImage);

  const {scrollY} = useScroll();
  const translateX = useTransform(scrollY, [0, 800], ['-15%', '-25%'], {clamp: true});
  const scale = useTransform(scrollY, [0, 800], [1.025, 1], {clamp: true});
  const opacity = useTransform(scrollY, [0, 800], [1, 0], {clamp: true});

  return (
    <motion.div css={styles.root}>
      {img != null && (
        <motion.div style={{x: translateX, scale, flex: 1, zIndex: -1, pointerEvents: 'none'}}>
          <GatsbyImage
            alt="Infinet on a basketball ring"
            css={{height: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`}}
            loading="eager"
            objectFit="contain"
            objectPosition="left center"
            image={img}
          />
        </motion.div>
      )}
      {textImage != null && (
        <motion.div css={styles.textImageContainer} style={{opacity, y: useTransform(scrollY, v => -v * 0.5)}}>
          <GatsbyImage
            alt="Infinet text logo and slogan"
            css={styles.textImage}
            loading="eager"
            objectFit="contain"
            image={textImage}
          />
        </motion.div>
      )}
    </motion.div>
  );
}

const styles = {
  root: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    width: '100%',
    height: '100vh',
    pointerEvents: 'none',
  }),
  textImageContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  }),
  title: css({
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 0,
  }),
  textImage: {
    width: '70%',
  },
};
