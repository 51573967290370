import * as React from 'react';

export default function InstagramLogo() {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.625 16.25H24.375C19.8877 16.25 16.25 19.8877 16.25 24.375V40.625C16.25 45.1123 19.8877 48.75 24.375 48.75H40.625C45.1123 48.75 48.75 45.1123 48.75 40.625V24.375C48.75 19.8877 45.1123 16.25 40.625 16.25Z"
        stroke="black"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9995 31.476C39.2001 32.8284 38.9691 34.2096 38.3394 35.4232C37.7097 36.6367 36.7134 37.6208 35.4922 38.2355C34.2709 38.8502 32.887 39.0641 31.5372 38.8469C30.1874 38.6297 28.9404 37.9924 27.9736 37.0256C27.0069 36.0589 26.3696 34.8119 26.1524 33.4621C25.9352 32.1123 26.1491 30.7283 26.7638 29.5071C27.3785 28.2859 28.3626 27.2896 29.5761 26.6599C30.7897 26.0302 32.1709 25.7992 33.5233 25.9998C34.9028 26.2043 36.1799 26.8471 37.166 27.8333C38.1521 28.8194 38.795 30.0965 38.9995 31.476Z"
        stroke="black"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
