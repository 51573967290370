import * as React from 'react';
import {css} from '@emotion/react';
import mq from 'constants/media';

type Props = {children: React.ReactNode; id?: string; rootStyle?: React.CSSProperties; style?: React.CSSProperties};

export default function Section({children, id, style, rootStyle}: Props) {
  return (
    <section css={styles.root} id={id} style={rootStyle}>
      <div css={styles.container} style={style}>
        {children}
      </div>
    </section>
  );
}

const styles = {
  root: css(
    mq({
      display: 'flex',
      justifyContent: 'center',
      padding: [32, 64],
      paddingTop: [64, 100],
      paddingBottom: [64, 100],
    })
  ),
  container: css({maxWidth: 1440, width: '100%'}),
};
