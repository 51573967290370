import * as React from 'react';

export default function PhoneLogo() {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.2744 22.75C38.8616 23.0597 40.3203 23.8359 41.4638 24.9794C42.6073 26.1229 43.3835 27.5816 43.6932 29.1687M37.2744 16.25C40.572 16.6163 43.647 18.093 45.9946 20.4376C48.3421 22.7822 49.8227 25.8554 50.1932 29.1525M48.5682 42.12V46.995C48.57 47.4476 48.4773 47.8955 48.296 48.3102C48.1147 48.7249 47.8488 49.0971 47.5153 49.403C47.1818 49.709 46.7881 49.9419 46.3594 50.0869C45.9307 50.2319 45.4764 50.2857 45.0257 50.245C40.0253 49.7017 35.2221 47.993 31.0019 45.2563C27.0757 42.7613 23.7469 39.4325 21.2519 35.5063C18.5057 31.267 16.7966 26.4404 16.2632 21.4175C16.2226 20.9681 16.276 20.5152 16.42 20.0876C16.564 19.66 16.7955 19.2671 17.0997 18.9339C17.4039 18.6006 17.7741 18.3344 18.1869 18.1521C18.5996 17.9698 19.0457 17.8754 19.4969 17.875H24.3719C25.1606 17.8672 25.9251 18.1465 26.5231 18.6607C27.121 19.175 27.5116 19.8891 27.6219 20.67C27.8277 22.2301 28.2093 23.7619 28.7594 25.2363C28.9781 25.8179 29.0254 26.45 28.8958 27.0577C28.7662 27.6654 28.4651 28.2232 28.0282 28.665L25.9644 30.7287C28.2777 34.797 31.6462 38.1655 35.7144 40.4787L37.7782 38.415C38.22 37.9781 38.7778 37.677 39.3855 37.5474C39.9932 37.4178 40.6253 37.4651 41.2069 37.6837C42.6813 38.2339 44.2131 38.6155 45.7732 38.8213C46.5626 38.9326 47.2835 39.3302 47.7988 39.9384C48.3141 40.5466 48.588 41.3231 48.5682 42.12Z"
        stroke="black"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
