import {graphql, useStaticQuery} from 'gatsby';
import * as React from 'react';

export const SEO = ({children}: {children?: React.ReactElement}) => {
  const {
    markdownRemark: {
      frontmatter: {siteInfo},
    },
    site: {siteMetadata},
  } = useStaticQuery(graphql`
    query SEOQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          siteInfo {
            seoDescription
            seoKeywords
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `);
  return (
    <>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteInfo.seoDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:url" content={siteMetadata.siteUrl} />
      <meta name="twitter:description" content={siteInfo.seoDescription} />
      <link rel="icon" href="images/favicon.ico" />

      {children}
    </>
  );
};
