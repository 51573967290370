import * as React from 'react';
import {useDevice} from 'hooks/index';
import Desktop from './Desktop';
import Mobile from './Mobile';

export default function Hero() {
  const {isDesktop} = useDevice();

  return isDesktop ? <Desktop /> : <Mobile />;
}
