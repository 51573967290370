import * as React from 'react';
import {css} from '@emotion/react';
import {h2, b1} from 'constants/fonts';
import Product from './Product';
import Section from '../Section';
import {graphql, useStaticQuery} from 'gatsby';
import {type ImageDataLike} from 'gatsby-plugin-image';

export type ProductsType = Readonly<{
  markdownRemark: {
    frontmatter: {
      products: {
        description: string;
        image: ImageDataLike;
        price?: number;
        title: string;
        pdf?: {
          publicURL: string;
        };
      }[];
    };
  };
}>;

export default function Products() {
  const {
    markdownRemark: {
      frontmatter: {products},
    },
  } = useStaticQuery<ProductsType>(graphql`
    query ProductsQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        id
        frontmatter {
          products {
            description
            price
            title
            image {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, width: 1000)
              }
            }
            pdf {
              publicURL
            }
          }
        }
      }
    }
  `);

  return (
    <div css={styles.outer} id="products">
      <Section>
        <div css={styles.root}>
          <h1 css={styles.title}>Products</h1>
          {products.map((product, i) => {
            return <Product {...product} key={product.title + i} />;
          })}
        </div>
      </Section>
    </div>
  );
}

const styles = {
  outer: css({
    backgroundColor: 'rgba(88,182,234,0.15)',
  }),
  root: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  title: [h2, css({marginBottom: 24})],
  body: {...b1},
};
