import * as React from 'react';

export default function MailLogo() {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 19.5H45.5C47.2875 19.5 48.75 20.9625 48.75 22.75V42.25C48.75 44.0375 47.2875 45.5 45.5 45.5H19.5C17.7125 45.5 16.25 44.0375 16.25 42.25V22.75C16.25 20.9625 17.7125 19.5 19.5 19.5Z"
        stroke="black"
        strokeWidth="2.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.75 22.75L32.5 34.125L16.25 22.75"
        stroke="black"
        strokeWidth="2.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
