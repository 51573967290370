import {useContext} from 'react';
import {DocumentContext} from 'contexts/DocumentProvider';

export default function useDevice() {
  const {device} = useContext(DocumentContext);

  return {
    device,
    isDesktop: device === 'desktop',
    isTablet: device === 'tablet',
    isMobile: device === 'mobile',
  };
}
