import * as React from 'react';
import {type ImageDataLike, GatsbyImage, getImage} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';

export type FooterType = Readonly<{
  markdownRemark: {
    frontmatter: {
      footerImage: ImageDataLike;
    };
  };
}>;

export default function Footer() {
  const {
    markdownRemark: {
      frontmatter: {footerImage},
    },
  } = useStaticQuery<FooterType>(graphql`
    query FooterQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        id
        frontmatter {
          footerImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const img = getImage(footerImage);

  return img != null ? <GatsbyImage alt="infinet on the court" objectFit="cover" image={img} /> : null;
}
