import React from 'react';
import {motion} from 'framer-motion';

type Props = {children: React.ReactNode};

export default function FadedScreen({children}: Props) {
  return (
    <motion.div animate={{opacity: 1}} initial={{opacity: 0}} key="faded-screen" transition={{duration: 0.66}}>
      {children}
    </motion.div>
  );
}
